// UpdateKeyMap
// This file contains a serialization and associated data for
// the mutable data in devices, installs and entities.
// To add a new setting:
//   1. Add the setting to the correct model, outside this file.
//   2. Add the setting to an enum here, or if it's multiple settings in
//      a nested structure, add a namespace following the examples below along
//      with enums anywhere where a primitive value / "leaf" value can be found.
//   3. Make sure any enums you create are added to the union type "settingsKeyType"
//      or to another type defined here if appropriate.
//   4. To make it so settings changes can be expressed in human-readable format,
//      add an entry for all your primitive value / "leaf" settings into the 'detailMap'
//      which can also be used to store other metadata about settings as well.
//   5. Make sure the settings are added to the SettingsUpdateFactory file, so
//      changes to the value will be considered a valid request.

// DEVICE NON-INHERITED SETTINGS
import cronstrue from 'cronstrue';

export enum DeviceNonInheritedSettingsKey {
    AssetId = 'assetId',
    CustomLocation = 'customLocation',
    Location = 'location',
    Note = 'note',
    SkipAlerts = 'skipAlerts',
    CustomSerialNum = 'customSerialNum'
}

export namespace DeviceNonInheritedSettingsKey {
    export const asArray: DeviceNonInheritedSettingsKey[] = [
        DeviceNonInheritedSettingsKey.AssetId,
        DeviceNonInheritedSettingsKey.CustomLocation,
        DeviceNonInheritedSettingsKey.Location,
        DeviceNonInheritedSettingsKey.Note,
        DeviceNonInheritedSettingsKey.SkipAlerts,
        DeviceNonInheritedSettingsKey.CustomSerialNum
    ];
    export const asArrayWithChildren: any[] = DeviceNonInheritedSettingsKey.asArray;
}

export type DeviceNonInheritedSettingsKeyType = DeviceNonInheritedSettingsKey;

// INSTALL NON-INHERITED SETTINGS

export enum InstallNonInheritedSettingsKey {}

export namespace InstallNonInheritedSettingsKey {
    export const asArray: InstallNonInheritedSettingsKey[] = [];
    export const asArrayWithChildren: any[] = InstallNonInheritedSettingsKey.asArray;
}

export type InstallNonInheritedSettingsKeyType = InstallNonInheritedSettingsKey;

// ENTITY NON-INHERITED SETTINGS

export enum EntityNonInheritedSettingsKey {
    IsDealer = 'isDealer',
    Name = 'name',
    Notes = 'notes',
    ExpiresOn = 'expiresOn',
    DynamicDeviceGroups = 'dynamicDeviceGroups',
}

export namespace EntityNonInheritedSettingsKey {
    export const asArray: EntityNonInheritedSettingsKey[] = [
        EntityNonInheritedSettingsKey.IsDealer,
        EntityNonInheritedSettingsKey.Name,
        EntityNonInheritedSettingsKey.Notes,
        EntityNonInheritedSettingsKey.ExpiresOn,
        EntityNonInheritedSettingsKey.DynamicDeviceGroups,
    ];
    export const asArrayWithChildren: any[] = EntityNonInheritedSettingsKey.asArray;
}

export type EntityNonInheritedSettingsKeyType = EntityNonInheritedSettingsKey;

// DYNAMIC SETTINGS

export enum DeviceAutoFulfillmentSuppliesNetworkSettingsMap {
    Enabled = 'autoFulfillment.suppliesNetwork.enabled'
}

export namespace DeviceAutoFulfillmentSuppliesNetworkSettingsMap {
    export const asArray: DeviceAutoFulfillmentSuppliesNetworkSettingsMap[] = [
        DeviceAutoFulfillmentSuppliesNetworkSettingsMap.Enabled
    ];
    export const asArrayWithChildren: any[] =
        DeviceAutoFulfillmentSuppliesNetworkSettingsMap.asArray;
}

export namespace DeviceAutoFulfillmentSettingsMap {
    export const SuppliesNetwork = DeviceAutoFulfillmentSuppliesNetworkSettingsMap;
    export const asArray: unknown[] = [];
    export const asArrayWithChildren: any[] = [...SuppliesNetwork.asArrayWithChildren];
}

export enum CurrentDeviceDiscoveryAutoScanSettingsMap {
    Use = 'deviceDiscovery.autoScan.use',
    Hops = 'deviceDiscovery.autoScan.hops',
    ScanOnInstall = 'deviceDiscovery.autoScan.scanOnInstall'
}

export namespace CurrentDeviceDiscoveryAutoScanSettingsMap {
    export const asArray: CurrentDeviceDiscoveryAutoScanSettingsMap[] = [
        CurrentDeviceDiscoveryAutoScanSettingsMap.Use,
        CurrentDeviceDiscoveryAutoScanSettingsMap.Hops,
        CurrentDeviceDiscoveryAutoScanSettingsMap.ScanOnInstall
    ];
    export const asArrayWithChildren: any[] = CurrentDeviceDiscoveryAutoScanSettingsMap.asArray;
}

export enum CurrentDeviceDiscoveryAutoScanForNewDevicesSettingsMap {
    DaysOfWeek = 'deviceDiscovery.autoScanForNewDevices.daysOfWeek'
}

export namespace CurrentDeviceDiscoveryAutoScanForNewDevicesSettingsMap {
    export const asArray: CurrentDeviceDiscoveryAutoScanForNewDevicesSettingsMap[] = [
        CurrentDeviceDiscoveryAutoScanForNewDevicesSettingsMap.DaysOfWeek
    ];
    export const asArrayWithChildren: any[] =
        CurrentDeviceDiscoveryAutoScanForNewDevicesSettingsMap.asArray;
}

export enum CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap {
    Use = 'deviceDiscovery.advancedDiscovery.use',
    Retry = 'deviceDiscovery.advancedDiscovery.retry',
    Timeout = 'deviceDiscovery.advancedDiscovery.timeout',
    PingFirst = 'deviceDiscovery.advancedDiscovery.pingFirst',
    Workers = 'deviceDiscovery.advancedDiscovery.workers'
}

export namespace CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap {
    export const asArray: CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap[] = [
        CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap.Use,
        CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap.Retry,
        CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap.Timeout,
        CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap.PingFirst,
        CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap.Workers,
    ];
    export const asArrayWithChildren: any[] =
        CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap.asArray;
}

export enum CurrentDeviceDiscoverySettingsMap {
    IPAddress = 'deviceDiscovery.ipaddress',
    ExcludeIPAddress = 'deviceDiscovery.excludeIPAddress'
}

export namespace CurrentDeviceDiscoverySettingsMap {
    export const AutoScan = CurrentDeviceDiscoveryAutoScanSettingsMap;
    export const AdvancedDiscovery = CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap;
    export const AutoScanForNewDevices = CurrentDeviceDiscoveryAutoScanForNewDevicesSettingsMap;
    export const asArray: CurrentDeviceDiscoverySettingsMap[] = [
        CurrentDeviceDiscoverySettingsMap.IPAddress,
        CurrentDeviceDiscoverySettingsMap.ExcludeIPAddress
    ];

    export const asArrayWithChildren: any[] = [
        ...CurrentDeviceDiscoverySettingsMap.asArray,
        ...CurrentDeviceDiscoverySettingsMap.AutoScan.asArrayWithChildren,
        ...CurrentDeviceDiscoverySettingsMap.AdvancedDiscovery.asArrayWithChildren,
        ...CurrentDeviceDiscoveryAutoScanForNewDevicesSettingsMap.asArrayWithChildren
    ];
}

export enum CurrentUploadTimeSettingsMap {
    DayPart = 'uploadTime.dayPart',
    TimeOfDay = 'uploadTime.timeOfDay'
}

export namespace CurrentUploadTimeSettingsMap {
    export const asArray: CurrentUploadTimeSettingsMap[] = [
        CurrentUploadTimeSettingsMap.DayPart,
        CurrentUploadTimeSettingsMap.TimeOfDay
    ];
    export const asArrayWithChildren: any[] = CurrentUploadTimeSettingsMap.asArray;
}

export enum CurrentAlertsTonerEmailsSettingsMap {
    Premature = 'alerts.toner.emails.premature',
    Replacement = 'alerts.toner.emails.replacement',
    Alert = 'alerts.toner.emails.alert'
}

export namespace CurrentAlertsTonerEmailsSettingsMap {
    export const asArray: CurrentAlertsTonerEmailsSettingsMap[] = [
        CurrentAlertsTonerEmailsSettingsMap.Premature,
        CurrentAlertsTonerEmailsSettingsMap.Replacement,
        CurrentAlertsTonerEmailsSettingsMap.Alert
    ];
    export const asArrayWithChildren: any[] = CurrentAlertsTonerEmailsSettingsMap.asArray;
}

export enum CurrentAlertsTonerSettingsMap {
    PrematureToggle = 'alerts.toner.prematureToggle',
    ReplacementToggle = 'alerts.toner.replacementToggle',
    AlertToggle = 'alerts.toner.alertToggle',
    SuspectedAlertToggle = 'alerts.toner.suspectedAlertToggle',
    DaysToDepletion = 'alerts.toner.daysToDepletion',
    PctRemaining = 'alerts.toner.pctRemaining',
    SeparateColorThreshold = 'alerts.toner.separateColorThresholdToggle',
    ColorPctRemaining = 'alerts.toner.colorPctRemaining',
    CheckFrequencyMinutes = 'alerts.toner.checkFrequencyMinutes'
}

export namespace CurrentAlertsTonerSettingsMap {
    export const Emails = CurrentAlertsTonerEmailsSettingsMap;
    export const asArray: CurrentAlertsTonerSettingsMap[] = [
        CurrentAlertsTonerSettingsMap.PrematureToggle,
        CurrentAlertsTonerSettingsMap.ReplacementToggle,
        CurrentAlertsTonerSettingsMap.AlertToggle,
        CurrentAlertsTonerSettingsMap.SuspectedAlertToggle,
        CurrentAlertsTonerSettingsMap.DaysToDepletion,
        CurrentAlertsTonerSettingsMap.PctRemaining,
        CurrentAlertsTonerSettingsMap.SeparateColorThreshold,
        CurrentAlertsTonerSettingsMap.ColorPctRemaining,
        CurrentAlertsTonerSettingsMap.CheckFrequencyMinutes
    ];
    export const asArrayWithChildren: any[] = [
        ...CurrentAlertsTonerSettingsMap.asArray,
        ...CurrentAlertsTonerSettingsMap.Emails.asArrayWithChildren
    ];
}

export enum CurrentAlertsServiceTypeSettingsMap {
    NotReachable = 'alerts.service.type.notReachable',
    DoorOpen = 'alerts.service.type.doorOpen',
    NoToner = 'alerts.service.type.noToner',
    SupplyMissing = 'alerts.service.type.supplyMissing',
    ServiceRequested = 'alerts.service.type.serviceRequested',
    OutOfPaper = 'alerts.service.type.outOfPaper',
    MaintKit = 'alerts.service.type.maintKit'
}

export namespace CurrentAlertsServiceTypeSettingsMap {
    export const asArray: CurrentAlertsServiceTypeSettingsMap[] = [
        CurrentAlertsServiceTypeSettingsMap.NotReachable,
        CurrentAlertsServiceTypeSettingsMap.DoorOpen,
        CurrentAlertsServiceTypeSettingsMap.NoToner,
        CurrentAlertsServiceTypeSettingsMap.SupplyMissing,
        CurrentAlertsServiceTypeSettingsMap.ServiceRequested,
        CurrentAlertsServiceTypeSettingsMap.OutOfPaper,
        CurrentAlertsServiceTypeSettingsMap.MaintKit
    ];
    export const asArrayWithChildren: any[] = CurrentAlertsServiceTypeSettingsMap.asArray;
}

export enum CurrentAlertsServiceTextSettingsMap {
    ServiceProvider = 'alerts.service.text.serviceProvider',
    Alert = 'alerts.service.text.alert'
}

export namespace CurrentAlertsServiceTextSettingsMap {
    export const asArray: CurrentAlertsServiceTextSettingsMap[] = [
        CurrentAlertsServiceTextSettingsMap.ServiceProvider,
        CurrentAlertsServiceTextSettingsMap.Alert
    ];
    export const asArrayWithChildren: any[] = CurrentAlertsServiceTextSettingsMap.asArray;
}

export enum CurrentAlertsServiceEmailsSettingsMap {
    ServiceProvider = 'alerts.service.emails.serviceProvider',
    Alert = 'alerts.service.emails.alert'
}

export namespace CurrentAlertsServiceEmailsSettingsMap {
    export const asArray: CurrentAlertsServiceEmailsSettingsMap[] = [
        CurrentAlertsServiceEmailsSettingsMap.ServiceProvider,
        CurrentAlertsServiceEmailsSettingsMap.Alert
    ];
    export const asArrayWithChildren: any[] = CurrentAlertsServiceEmailsSettingsMap.asArray;
}

export enum CurrentAlertsServiceMisfeedSettingsMap {
    Enabled = 'alerts.service.misfeed.enabled'
}

export namespace CurrentAlertsServiceMisfeedSettingsMap {
    export const asArray: CurrentAlertsServiceMisfeedSettingsMap[] = [
        CurrentAlertsServiceMisfeedSettingsMap.Enabled
    ];
    export const asArrayWithChildren: any[] = CurrentAlertsServiceMisfeedSettingsMap.asArray;
}

export enum CurrentAlertsServiceSettingsMap {
    MaintKitPctRemaining = 'alerts.service.maintKitPctRemaining',
    CheckFrequencyMinutes = 'alerts.service.checkFrequencyMinutes'
}

export namespace CurrentAlertsServiceSettingsMap {
    export const Type = CurrentAlertsServiceTypeSettingsMap;
    export const Text = CurrentAlertsServiceTextSettingsMap;
    export const Emails = CurrentAlertsServiceEmailsSettingsMap;
    export const Misfeed = CurrentAlertsServiceMisfeedSettingsMap;
    export const asArray: CurrentAlertsServiceSettingsMap[] = [
        CurrentAlertsServiceSettingsMap.MaintKitPctRemaining,
        CurrentAlertsServiceSettingsMap.CheckFrequencyMinutes
    ];
    export const asArrayWithChildren: any[] = [
        ...CurrentAlertsServiceSettingsMap.asArray,
        ...CurrentAlertsServiceSettingsMap.Type.asArrayWithChildren,
        ...CurrentAlertsServiceSettingsMap.Text.asArrayWithChildren,
        ...CurrentAlertsServiceSettingsMap.Emails.asArrayWithChildren,
        ...CurrentAlertsServiceSettingsMap.Misfeed.asArrayWithChildren
    ];
}

export enum AlertsEstimatedDepletionSettingsMap {
    Enabled = 'alerts.estimatedDepletion.enabled',
    Days = 'alerts.estimatedDepletion.days',
}

export namespace AlertsEstimatedDepletionSettingsMap {
    export const asArray: AlertsEstimatedDepletionSettingsMap[] = [
        AlertsEstimatedDepletionSettingsMap.Enabled,
        AlertsEstimatedDepletionSettingsMap.Days,
    ];
    export const asArrayWithChildren: any[] = [
        ...AlertsEstimatedDepletionSettingsMap.asArray,
    ];
}

export enum CurrentAlertsVolumeMap {
    Enabled = 'alerts.volume.enabled',
    Beginning = 'alerts.volume.beginning',
    Recurring = 'alerts.volume.recurring',
    Frequency = 'alerts.volume.frequency',
    Emails = 'alerts.volume.emails'
}

export namespace CurrentAlertsVolumeMap {
    export const asArray: CurrentAlertsVolumeMap[] = [
        CurrentAlertsVolumeMap.Enabled,
        CurrentAlertsVolumeMap.Beginning,
        CurrentAlertsVolumeMap.Recurring,
        CurrentAlertsVolumeMap.Frequency,
        CurrentAlertsVolumeMap.Emails
    ];
    export const asArrayWithChildren: any[] = CurrentAlertsVolumeMap.asArray;
}

export enum CurrentAlertsDeviceNewSettingsMap {
    Enabled = 'alerts.device.new.enabled',
    Emails = 'alerts.device.new.emails'
}

export namespace CurrentAlertsDeviceNewSettingsMap {
    export const asArray: CurrentAlertsDeviceNewSettingsMap[] = [
        CurrentAlertsDeviceNewSettingsMap.Enabled,
        CurrentAlertsDeviceNewSettingsMap.Emails
    ];
}

export enum CurrentAlertsDeviceOfflineSettingsMap {
    Enabled = 'alerts.device.offline.enabled',
    Emails = 'alerts.device.offline.emails'
}

export namespace CurrentAlertsDeviceOfflineSettingsMap {
    export const asArray: CurrentAlertsDeviceOfflineSettingsMap[] = [
        CurrentAlertsDeviceOfflineSettingsMap.Enabled,
        CurrentAlertsDeviceOfflineSettingsMap.Emails
    ];
}

export namespace CurrentAlertsDeviceSettingsMap {
    export const New = CurrentAlertsDeviceNewSettingsMap;
    export const Offline = CurrentAlertsDeviceOfflineSettingsMap;
    export const asArrayWithChildren: any[] = [
        ...CurrentAlertsDeviceSettingsMap.New.asArray,
        ...CurrentAlertsDeviceSettingsMap.Offline.asArray
    ];
}

export enum AlertsInstallNewSettingsMap {
    Enabled = 'alerts.install.new.enabled',
    Emails = 'alerts.install.new.emails'
}

export namespace AlertsInstallNewSettingsMap {
    export const asArray: AlertsInstallNewSettingsMap[] = [
        AlertsInstallNewSettingsMap.Enabled,
        AlertsInstallNewSettingsMap.Emails
    ];
}

export enum AlertsInstallStaleSettingsMap {
    Enabled = 'alerts.install.stale.enabled',
    Emails = 'alerts.install.stale.emails',
    StaleDays = 'alerts.install.stale.staleDays'
}

export namespace AlertsInstallStaleSettingsMap {
    export const asArray: AlertsInstallStaleSettingsMap[] = [
        AlertsInstallStaleSettingsMap.Enabled,
        AlertsInstallStaleSettingsMap.Emails,
        AlertsInstallStaleSettingsMap.StaleDays,
    ];
}


export enum AlertsInstallStaleBackOnlineSettingsMap {
    Enabled = 'alerts.install.staleBackOnline.enabled',
    Emails = 'alerts.install.staleBackOnline.emails',
    StaleDays = 'alerts.install.staleBackOnline.staleDays'
}

export namespace AlertsInstallStaleBackOnlineSettingsMap {
    export const asArray: AlertsInstallStaleBackOnlineSettingsMap[] = [
        AlertsInstallStaleBackOnlineSettingsMap.Enabled,
        AlertsInstallStaleBackOnlineSettingsMap.Emails,
        AlertsInstallStaleBackOnlineSettingsMap.StaleDays,
    ];
}

export namespace AlertsInstallSettingsMap {
    export const New = AlertsInstallNewSettingsMap;
    export const Stale = AlertsInstallStaleSettingsMap;
    export const StaleBackOnline = AlertsInstallStaleBackOnlineSettingsMap;
    export const asArrayWithChildren: any[] = [
        ...AlertsInstallNewSettingsMap.asArray,
        ...AlertsInstallStaleSettingsMap.asArray,
        ...AlertsInstallStaleBackOnlineSettingsMap.asArray,
    ];
}

export enum CurrentAlertsSettingsMap {
    UploadMetersOnAlert = 'alerts.uploadMetersOnAlert'
}

export namespace CurrentAlertsSettingsMap {
    export const Toner = CurrentAlertsTonerSettingsMap;
    export const Service = CurrentAlertsServiceSettingsMap;
    export const Volume = CurrentAlertsVolumeMap;
    export const Device = CurrentAlertsDeviceSettingsMap;
    export const Install = AlertsInstallSettingsMap;
    export const EstimatedDepletion = AlertsEstimatedDepletionSettingsMap;
    export const asArray: CurrentAlertsSettingsMap[] = [
        CurrentAlertsSettingsMap.UploadMetersOnAlert
    ];
    export const asArrayWithChildren: any[] = [
        ...CurrentAlertsSettingsMap.asArray,
        ...CurrentAlertsSettingsMap.Toner.asArrayWithChildren,
        ...CurrentAlertsSettingsMap.Service.asArrayWithChildren,
        ...CurrentAlertsSettingsMap.Device.asArrayWithChildren,
        ...CurrentAlertsSettingsMap.Install.asArrayWithChildren,
        ...CurrentAlertsSettingsMap.EstimatedDepletion.asArrayWithChildren,
        ...CurrentAlertsVolumeMap.asArrayWithChildren
    ];
}

export enum CurrentSNMPSettings {
    Community = 'snmp.community',
    Communities = 'snmp.communities',
    Version = 'snmp.version',
    V3Username = 'snmp.v3Username',
    V3AuthPassword = 'snmp.v3AuthPassword',
    V3PrivacyPassword = 'snmp.v3PrivacyPassword',
    V3PrivacyProtocol = 'snmp.v3PrivacyProtocol',
    V3Algorithm = 'snmp.v3Algorithm',
    V3Context = 'snmp.v3Context'
}

export namespace CurrentSNMPSettings {
    export const asArray: CurrentSNMPSettings[] = [
        CurrentSNMPSettings.Community,
        CurrentSNMPSettings.Communities,
        CurrentSNMPSettings.Version,
        CurrentSNMPSettings.V3Username,
        CurrentSNMPSettings.V3Algorithm,
        CurrentSNMPSettings.V3PrivacyPassword,
        CurrentSNMPSettings.V3PrivacyProtocol,
        CurrentSNMPSettings.V3AuthPassword,
        CurrentSNMPSettings.V3Context
    ];
    export const asArrayWithChildren: any[] = CurrentSNMPSettings.asArray;
}

export enum CurrentMeterFlagSettings {
    FlagArray = 'meterFlags.flagArray',
    TesseractVerbosity = 'meterFlags.tesseractVerbosity'
}

export namespace CurrentMeterFlagsSettingsMap {
    export const FlagArray = CurrentMeterFlagSettings.FlagArray;
    export const TesseractVerbosity = CurrentMeterFlagSettings.TesseractVerbosity;
    export const asArray: CurrentMeterFlagSettings[] = [CurrentMeterFlagSettings.FlagArray];
    export const asArrayWithChildren: any[] = CurrentMeterFlagsSettingsMap.asArray;
}

export enum CurrentRtsGeneralSettings {
    Enabled = 'rts.enabled'
}

export namespace CurrentRtsSettingsMap {
    export const Enabled = CurrentRtsGeneralSettings.Enabled;
    export const asArray: CurrentRtsGeneralSettings[] = [CurrentRtsGeneralSettings.Enabled];
    export const asArrayWithChildren: any[] = CurrentRtsSettingsMap.asArray;
}

export enum CurrentDataGatheringDgiSettings {
    Key = 'dataGathering.dgi.key'
}

export namespace CurrentDataGatheringDgiSettingsMap {
    export const Key = CurrentDataGatheringDgiSettings.Key;
    export const asArray: CurrentDataGatheringDgiSettings[] = [CurrentDataGatheringDgiSettings.Key];
    export const asArrayWithChildren: any[] = CurrentDataGatheringDgiSettingsMap.asArray;
}

export enum CurrentDataGatheringTrackingStatusSettings {
    Enabled = 'dataGathering.trackingStatus.enabled'
}

export namespace CurrentDataGatheringTrackingStatusSettingsMap {
    export const Enabled = CurrentDataGatheringTrackingStatusSettings.Enabled;
    export const asArray: CurrentDataGatheringTrackingStatusSettings[] = [
        CurrentDataGatheringTrackingStatusSettings.Enabled
    ];
    export const asArrayWithChildren: any[] = CurrentDataGatheringTrackingStatusSettingsMap.asArray;
}

export enum CurrentDataGatheringScheduleSettings {
    Meter = 'dataGathering.schedules.meter'
}

export namespace CurrentDataGatheringSchedulesSettingsMap {
    export const Meter = CurrentDataGatheringScheduleSettings.Meter;
    export const asArray: CurrentDataGatheringScheduleSettings[] = [
        CurrentDataGatheringScheduleSettings.Meter
    ];
    export const asArrayWithChildren: any[] = CurrentDataGatheringSchedulesSettingsMap.asArray;
}

export enum CurrentDataGatheringEmbeddedWebpageCredentialsSettings {
    Username = 'dataGathering.embeddedWebpage.credentials.username',
    Password = 'dataGathering.embeddedWebpage.credentials.password'
}

export namespace CurrentDataGatheringEmbeddedWebpageCredentialsSettingsMap {
    export const Username = CurrentDataGatheringEmbeddedWebpageCredentialsSettings.Username;
    export const Password = CurrentDataGatheringEmbeddedWebpageCredentialsSettings.Password;
    export const asArray: CurrentDataGatheringEmbeddedWebpageCredentialsSettings[] = [
        CurrentDataGatheringEmbeddedWebpageCredentialsSettingsMap.Username,
        CurrentDataGatheringEmbeddedWebpageCredentialsSettingsMap.Password
    ];
    export const asArrayWithChildren: any[] = [
        ...CurrentDataGatheringEmbeddedWebpageCredentialsSettingsMap.asArray
    ];
}

export namespace CurrentDataGatheringEmbeddedWebpageSettingsMap {
    export const Credentials = CurrentDataGatheringEmbeddedWebpageCredentialsSettingsMap;
    export const asArray: any[] = [];
    export const asArrayWithChildren: any[] = [
        ...CurrentDataGatheringEmbeddedWebpageSettingsMap.asArray,
        ...CurrentDataGatheringEmbeddedWebpageSettingsMap.Credentials.asArrayWithChildren
    ];
}

export enum CurrentDataGatheringSettings {
    Concurrency = 'dataGathering.concurrency'
}

export namespace CurrentDataGatheringSettingsMap {
    export const Dgi = CurrentDataGatheringDgiSettingsMap;
    export const TrackingStatus = CurrentDataGatheringTrackingStatusSettingsMap;
    export const Schedules = CurrentDataGatheringSchedulesSettingsMap;
    export const EmbeddedWebpage = CurrentDataGatheringEmbeddedWebpageSettingsMap;
    export const Concurrency = CurrentDataGatheringSettings.Concurrency;
    export const asArray: any[] = [CurrentDataGatheringSettingsMap.Concurrency];
    export const asArrayWithChildren: any[] = [
        ...CurrentDataGatheringSettingsMap.asArray,
        ...CurrentDataGatheringSettingsMap.Dgi.asArrayWithChildren,
        ...CurrentDataGatheringSettingsMap.TrackingStatus.asArrayWithChildren,
        ...CurrentDataGatheringSettingsMap.Schedules.asArrayWithChildren,
        ...CurrentDataGatheringSettingsMap.EmbeddedWebpage.asArrayWithChildren
    ];
}

export enum CurrentRemoteTechnicianSettings {
    Enabled = 'remoteTechnician.enabled'
}

export namespace CurrentRemoteTechnicianSettingsMap {
    export const Enabled = CurrentRemoteTechnicianSettings.Enabled;
    export const asArray: CurrentRemoteTechnicianSettings[] = [
        CurrentRemoteTechnicianSettings.Enabled
    ];
    export const asArrayWithChildren: any[] = CurrentRemoteTechnicianSettingsMap.asArray;
}

export enum CurrentUpgradeAutoSettings {
    Disabled = 'upgrade.auto.disabled',
    Schedule = 'upgrade.auto.schedule'
}

export namespace CurrentUpgradeAutoSettingsMap {
    export const Disabled = CurrentUpgradeAutoSettings.Disabled;
    export const Schedule = CurrentUpgradeAutoSettings.Schedule;
    export const asArray: CurrentUpgradeAutoSettings[] = [
        CurrentUpgradeAutoSettings.Disabled,
        CurrentUpgradeAutoSettings.Schedule
    ];
    export const asArrayWithChildren: any[] = asArray;
}

export namespace CurrentUpgradeSettingsMap {
    export const Auto = CurrentUpgradeAutoSettingsMap;
    export const asArray: any[] = [];
    export const asArrayWithChildren: any[] = Auto.asArrayWithChildren;
}

export enum CurrentBillingPreferencesScheduleSettings {
    Enabled = 'billingPreferences.schedule.enabled',
    Expression = 'billingPreferences.schedule.expression',
    Timezone = 'billingPreferences.schedule.timezone'
}

export namespace CurrentBillingPreferencesScheduleSettingsMap {
    export const Enabled = CurrentBillingPreferencesScheduleSettings.Enabled;
    export const Expression = CurrentBillingPreferencesScheduleSettings.Expression;
    export const Timezone = CurrentBillingPreferencesScheduleSettings.Timezone;
    export const asArray: CurrentBillingPreferencesScheduleSettings[] = [
        CurrentBillingPreferencesScheduleSettings.Enabled,
        CurrentBillingPreferencesScheduleSettings.Expression,
        CurrentBillingPreferencesScheduleSettings.Timezone
    ];
    export const asArrayWithChildren: any[] = [
        ...CurrentBillingPreferencesScheduleSettingsMap.asArray
    ];
}

export enum CurrentBillingPreferencesSettings {
    Counters = 'billingPreferences.counters'
}

export namespace CurrentBillingPreferencesSettingsMap {
    export const Counters = CurrentBillingPreferencesSettings.Counters;
    export const Schedule = CurrentBillingPreferencesScheduleSettingsMap;
    export const asArray = [CurrentBillingPreferencesSettingsMap.Counters];
    export const asArrayWithChildren = [
        ...CurrentBillingPreferencesSettingsMap.asArray,
        ...CurrentBillingPreferencesSettingsMap.Schedule.asArrayWithChildren
    ];
}

export enum OnsiteContactSettings {
    Phone = 'generalInformation.onsiteContact.phone',
    Email = 'generalInformation.onsiteContact.email',
    Name = 'generalInformation.onsiteContact.name'
}

export namespace OnsiteContactSettingsMap {
    export const Phone = OnsiteContactSettings.Phone;
    export const Email = OnsiteContactSettings.Email;
    export const Name = OnsiteContactSettings.Name;
    export const asArray = [
        OnsiteContactSettingsMap.Phone,
        OnsiteContactSettingsMap.Email,
        OnsiteContactSettingsMap.Name
    ];
    export const asArrayWithChildren = [...OnsiteContactSettingsMap.asArray];
}

export namespace GeneralInformationSettingsMap {
    export const OnsiteContact = OnsiteContactSettingsMap;
    export const asArray = [];
    export const asArrayWithChildren = [
        ...GeneralInformationSettingsMap.asArray,
        ...GeneralInformationSettingsMap.OnsiteContact.asArrayWithChildren
    ];
}

export enum DistributorSettingsMap {
    RejectMetersFromInactiveEntities = 'distributor.rejectMetersFromInactiveEntities',
}

export namespace DistributorSettingsMap {
    export const asArray = [
        DistributorSettingsMap.RejectMetersFromInactiveEntities
    ];

    export const asArrayWithChildren = [
        ...DistributorSettingsMap.asArray,
    ];
}


export enum EAutomateSalesQuoteSettingsMap {
    Enabled = 'integrations.eautomate.salesQuote.enabled',
}

export namespace EAutomateSalesQuoteSettingsMap {
    export const asArray: EAutomateSalesQuoteSettingsMap[] = [
        EAutomateSalesQuoteSettingsMap.Enabled,
    ];
    export const asArrayWithChildren: any[] = [
        ...EAutomateSalesQuoteSettingsMap.asArray,
    ];
}

export namespace IntegrationsEAutomateSettingsMap {
    export const SalesQuote = EAutomateSalesQuoteSettingsMap;
    export const asArray: any[] = [];
    export const asArrayWithChildren: any[] = [
        ...IntegrationsEAutomateSettingsMap.asArray,
        ...EAutomateSalesQuoteSettingsMap.asArrayWithChildren,
    ];
}

export namespace IntegrationsSettingsMap {
    export const EAutomate = IntegrationsEAutomateSettingsMap;
    export const asArray: any[] = [];
    export const asArrayWithChildren: any[] = [
        ...IntegrationsSettingsMap.asArray,
        ...IntegrationsEAutomateSettingsMap.asArrayWithChildren
    ];
}

export enum SuppliesConsolidationSettingsMap {
    WithinPercentRemaining = 'supplies.consolidation.withinPercentRemaining',
    WithinDaysRemaining = 'supplies.consolidation.withinDaysRemaining'
}

export namespace SuppliesConsolidationSettingsMap {
    export const asArray: SuppliesConsolidationSettingsMap[] = [
        SuppliesConsolidationSettingsMap.WithinPercentRemaining,
        SuppliesConsolidationSettingsMap.WithinDaysRemaining
    ];
    export const asArrayWithChildren: any[] = SuppliesConsolidationSettingsMap.asArray;
}

export namespace SuppliesSettingsMap {
    export const Consolidation = SuppliesConsolidationSettingsMap;
    export const asArray: any[] = [];
    export const asArrayWithChildren: any[] = [
        ...SuppliesSettingsMap.asArray,
        ...SuppliesConsolidationSettingsMap.asArrayWithChildren
    ];
}

export enum SdsSettingsMap {
    Enabled = 'sds.enabled'
}

export namespace SdsSettingsMap {
    export const asArray = [SdsSettingsMap.Enabled];
    export const asArrayWithChildren = [...SdsSettingsMap.asArray];
}

export enum SettingsKeyMap {
    FeatureFlags = 'featureFlags',
    PageCountType = 'pageCountType'
}

export namespace SettingsKeyMap {
    export const GeneralInformation = GeneralInformationSettingsMap;
    export const AutoFulfillment = DeviceAutoFulfillmentSettingsMap;
    export const DeviceDiscovery = CurrentDeviceDiscoverySettingsMap;
    export const UploadTime = CurrentUploadTimeSettingsMap;
    export const Alerts = CurrentAlertsSettingsMap;
    export const SNMP = CurrentSNMPSettings;
    export const MeterFlags = CurrentMeterFlagsSettingsMap;
    export const Rts = CurrentRtsSettingsMap;
    export const DataGathering = CurrentDataGatheringSettingsMap;
    export const RemoteTechnician = CurrentRemoteTechnicianSettingsMap;
    export const Upgrade = CurrentUpgradeSettingsMap;
    export const BillingPreferences = CurrentBillingPreferencesSettingsMap;
    export const Distributor = DistributorSettingsMap;
    export const Integrations = IntegrationsSettingsMap;
    export const Supplies = SuppliesSettingsMap;
    export const Sds = SdsSettingsMap;
    export const asArray: SettingsKeyMap[] = [
        SettingsKeyMap.PageCountType,
        SettingsKeyMap.FeatureFlags
    ];
    export const asArrayWithChildren: any[] = [
        ...SettingsKeyMap.asArray,
        ...SettingsKeyMap.GeneralInformation.asArrayWithChildren,
        ...SettingsKeyMap.AutoFulfillment.asArrayWithChildren,
        ...SettingsKeyMap.DeviceDiscovery.asArrayWithChildren,
        ...SettingsKeyMap.UploadTime.asArrayWithChildren,
        ...SettingsKeyMap.Alerts.asArrayWithChildren,
        ...SettingsKeyMap.SNMP.asArrayWithChildren,
        ...SettingsKeyMap.MeterFlags.asArrayWithChildren,
        ...SettingsKeyMap.Rts.asArrayWithChildren,
        ...SettingsKeyMap.Upgrade.asArrayWithChildren,
        ...SettingsKeyMap.DataGathering.asArrayWithChildren,
        ...SettingsKeyMap.RemoteTechnician.asArrayWithChildren,
        ...SettingsKeyMap.BillingPreferences.asArrayWithChildren,
        ...SettingsKeyMap.Distributor.asArrayWithChildren,
        ...SettingsKeyMap.Integrations.asArrayWithChildren,
        ...SettingsKeyMap.Supplies.asArrayWithChildren,
        ...SettingsKeyMap.Sds.asArrayWithChildren
    ];
}

export type DynamicSettingsKeyType =
    | DeviceAutoFulfillmentSuppliesNetworkSettingsMap
    | CurrentDeviceDiscoveryAutoScanSettingsMap
    | CurrentDeviceDiscoveryAdvancedDiscoverySettingsMap
    | CurrentDeviceDiscoveryAutoScanForNewDevicesSettingsMap
    | CurrentDeviceDiscoverySettingsMap
    | CurrentUploadTimeSettingsMap
    | CurrentAlertsTonerEmailsSettingsMap
    | CurrentAlertsTonerSettingsMap
    | CurrentAlertsServiceTypeSettingsMap
    | CurrentAlertsServiceTextSettingsMap
    | CurrentAlertsServiceEmailsSettingsMap
    | CurrentAlertsServiceSettingsMap
    | CurrentAlertsServiceMisfeedSettingsMap
    | CurrentAlertsVolumeMap
    | CurrentAlertsSettingsMap
    | CurrentAlertsDeviceNewSettingsMap
    | CurrentAlertsDeviceOfflineSettingsMap
    | CurrentSNMPSettings
    | SettingsKeyMap
    | CurrentMeterFlagSettings
    | CurrentRtsGeneralSettings
    | CurrentDataGatheringDgiSettings
    | CurrentRemoteTechnicianSettings
    | CurrentDataGatheringScheduleSettings
    | CurrentDataGatheringTrackingStatusSettings
    | CurrentUpgradeAutoSettings
    | CurrentBillingPreferencesSettings
    | CurrentBillingPreferencesScheduleSettings
    | AlertsInstallNewSettingsMap
    | CurrentDataGatheringSettings
    | CurrentDataGatheringEmbeddedWebpageCredentialsSettings
    | OnsiteContactSettings
    | AlertsInstallStaleBackOnlineSettingsMap
    | AlertsInstallStaleSettingsMap
    | DistributorSettingsMap
    | EAutomateSalesQuoteSettingsMap
    | SuppliesConsolidationSettingsMap
    | SdsSettingsMap
    | AlertsEstimatedDepletionSettingsMap;

export namespace UpdateKeyMap {
    export const Device = DeviceNonInheritedSettingsKey;
    export const Entity = EntityNonInheritedSettingsKey;
    export const Install = InstallNonInheritedSettingsKey;
    export const Settings = SettingsKeyMap;
    export const asArrayWithChildren: any[] = [
        ...UpdateKeyMap.Device.asArrayWithChildren,
        ...UpdateKeyMap.Install.asArrayWithChildren,
        ...UpdateKeyMap.Entity.asArrayWithChildren,
        ...UpdateKeyMap.Settings.asArrayWithChildren
    ];
}

export type UpdateKeyType =
    | DeviceNonInheritedSettingsKeyType
    | EntityNonInheritedSettingsKeyType
    | InstallNonInheritedSettingsKeyType
    | DynamicSettingsKeyType;

// Spencer 4/21/2020
// Below we create a system to map UpdateKeyType to functions that generate
// a human-readable description of each key.

// This interface defines the format we store our details in.
// We can expand this down the road-- i.e., to include the 'whats this?' descriptions.
export interface SettingDetails {
    name: string;
    getReadableValue: (value: any) => string;
}

export namespace SettingDetails {
    // Functions that interpret values of our settings types go here. If we have a type
    // that needs to be interpreted a special way, we can add a new function for it.
    // Otherwise, we can fallback to a more general function.
    // These NEED to handle null and undefined.
    const valFuncs: { [key: string]: (value: any) => string } = {};

    // Wraps functions in null check (reducing code redundancy)
    function safetyWrap<T>(
        f: (value: T) => string,
        fallback: string = 'Unspecified'
    ): (value: T) => string {
        return function(value: T): string {
            return value !== null && value !== undefined ? f(value) : fallback;
        };
    }

    // Default (works in many cases)
    valFuncs[''] = safetyWrap((value: object): string => value.toString());
    // Email function - omits 'overwrite' and removes elements containing only 'overwrite' before outputting
    valFuncs['overwritableArray'] = safetyWrap((value: object): string => {
        if (Array.isArray(value)) {
            return value
                .filter((v: any): boolean => v !== 'overwrite:')
                .map((q: any): string => {
                    if (typeof q === 'string') {
                        if (q.startsWith('overwrite:')) {
                            return q.slice(10);
                        } else {
                            return q;
                        }
                    }
                    return '';
                })
                .toString();
        } else {
            return null;
        }
    });
    // Boolean Functions
    valFuncs['boolean'] = safetyWrap((value: boolean): string => (value ? 'True' : 'False'));
    valFuncs['enabled/disabled'] = safetyWrap((value: boolean): string =>
        value ? 'Enabled' : 'Disabled'
    );
    valFuncs['enable/disabled(inversed)'] = safetyWrap((value: boolean): string =>
        value ? 'Disabled' : 'Enabled'
    );
    // Number Functions
    valFuncs['seconds'] = safetyWrap((value: number): string => `${value.toString()} seconds`);
    valFuncs['minutes'] = safetyWrap((value: number): string => `${value.toString()} minutes`);
    valFuncs['dollars'] = safetyWrap((value: number): string => `\$${value.toFixed(2)}`);
    valFuncs['retries'] = safetyWrap((value: number): string => `${value} retries`);
    // Object function (temporary)
    valFuncs['object'] = safetyWrap((value: any): string => 'a new value');
    // Special cron function
    valFuncs['cron'] = safetyWrap((value: string): string => cronstrue.toString(value));

    // Spencer 4/24/2020 - Note on the detailMap
    // detailMap is designed to be used to access information about how to interpret a SettingUpdate
    // in a human-readable way.
    // Current format for setting names is either like a title, when possible, and if that is awkward
    // or not descriptive enough (IsDealer is a good example of this) then single-quotes encapsulate a
    // a regularly-capitalized, descriptive phrase.
    const detailMap: { [S in UpdateKeyType]?: SettingDetails } = {};
    // Boolean settings
    detailMap[UpdateKeyMap.Entity.IsDealer] = {
        name: 'Entity Is Dealer',
        getReadableValue: valFuncs['boolean']
    };
    detailMap[UpdateKeyMap.Settings.FeatureFlags] = {
        name: 'Feature Flags',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.AutoScan.Use] = {
        name: 'Auto Device Scan',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.Use] = {
        name: 'Advanced Discovery',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.PingFirst] = {
        name: 'Device Discovery Ping First',
        getReadableValue: valFuncs['boolean']
    };
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.Workers] = {
        name: 'Device Discovery Workers',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.PrematureToggle] = {
        name: 'Premature Toner Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.ReplacementToggle] = {
        name: 'Replacement Toner Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.AlertToggle] = {
        name: 'Toner Alert Toggle',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.SuspectedAlertToggle] = {
        name: 'Suspected Toner Alert Toggle',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Type.NotReachable] = {
        name: 'Not-Reachable Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Type.DoorOpen] = {
        name: 'Door Open Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Type.NoToner] = {
        name: 'No Toner Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Type.SupplyMissing] = {
        name: 'Supply Missing Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Type.ServiceRequested] = {
        name: 'Service Requested Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Misfeed.Enabled] = {
        name: 'Misfeed/Paper jam Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Type.OutOfPaper] = {
        name: 'Out-of-Paper Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Type.MaintKit] = {
        name: 'Maintenance Kit Alert',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Install.New.Enabled] = {
        name: 'New Install Alert Toggle',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Install.StaleBackOnline.Enabled] = {
        name: 'Stale Install Back Online Alert Toggle',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Install.Stale.Enabled] = {
        name: 'Stale Install Alert Toggle',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.UploadMetersOnAlert] = {
        name: 'Upload Meters on Alert',
        getReadableValue: valFuncs['boolean']
    };
    detailMap[UpdateKeyMap.Settings.Rts.Enabled] = {
        name: 'Real-time Connection',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.RemoteTechnician.Enabled] = {
        name: 'Remote Technician Allowed',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.DataGathering.TrackingStatus.Enabled] = {
        name: 'Tracking Status',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Upgrade.Auto.Disabled] = {
        name: 'Auto Upgrade Allowed',
        getReadableValue: valFuncs['enable/disabled(inversed)']
    };
    detailMap[UpdateKeyMap.Settings.BillingPreferences.Schedule.Enabled] = {
        name: 'Billing Schedule Enabled',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Distributor.RejectMetersFromInactiveEntities] = {
        name: 'Reject Meters from Inactive Entities',
        getReadableValue: valFuncs['enabled/disabled']
    };

    // Float settings
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.Retry] = {
        name: 'Retry Advanced Discovery',
        getReadableValue: valFuncs['retries']
    };
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.AdvancedDiscovery.Timeout] = {
        name: 'Advanced Discovery Timeout',
        getReadableValue: valFuncs['seconds']
    };

    // Integer Array settings
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.AutoScanForNewDevices.DaysOfWeek] = {
        name: 'AutoScan Days of Week',
        getReadableValue: valFuncs['']
    };

    // Integer settings
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.AutoScan.Hops] = {
        name: 'AutoScan Hops',
        getReadableValue: valFuncs[''] // todo - this needs to actually list week days
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.DaysToDepletion] = {
        name: 'Toner Days to Depletion',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.PctRemaining] = {
        name: 'Toner Percent Remaining Alert',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.ColorPctRemaining] = {
        name: 'Color Toner Percent Remaining Alert',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.SeparateColorThreshold] = {
        name: 'Use Separate Color Toner Alert Threshold',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.CheckFrequencyMinutes] = {
        name: 'Alert Check Frequency (Minutes)',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.MaintKitPctRemaining] = {
        name: 'Maintenance Kit Percent Remaining',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.CheckFrequencyMinutes] = {
        name: 'Service Alert Check-Frequency (minutes)',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Volume.Enabled] = {
        name: 'Volume Alert Enabled/Disabled',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Volume.Beginning] = {
        name: 'Volume Alert Beginning',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Volume.Recurring] = {
        name: 'Volume Alerts Recurring',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.AutoFulfillment.SuppliesNetwork.Enabled] = {
        name: 'Supplies Network Auto-Fulfillment Enabled/Disabled',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Volume.Frequency] = {
        name: 'Volume Alert Frequency',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Volume.Emails] = {
        name: 'Volume Alerts Emails',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Device.New.Enabled] = {
        name: 'New Device Alert Enabled/Disabled',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Device.New.Emails] = {
        name: 'New Device Alert Emails',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Device.Offline.Enabled] = {
        name: 'Offline Device Alert Enabled/Disabled',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Device.Offline.Emails] = {
        name: 'Offline Device Alert Emails',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.IPAddress] = {
        name: 'Device Discovery IP Addresses',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.DeviceDiscovery.ExcludeIPAddress] = {
        name: 'Exclude IP Addresses',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Install.StaleBackOnline.StaleDays] = {
        name: 'Stale Install Back Online Stale Days',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Install.Stale.StaleDays] = {
        name: 'Stale Install Stale Days',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.EstimatedDepletion.Enabled] = {
        name: 'Estimated Depletion Alerts Enabled/Disabled',
        getReadableValue: valFuncs['enabled/disabled']
    };

    // Integer settings
    detailMap[UpdateKeyMap.Settings.SNMP.Version] = {
        name: 'SNMP Version',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.MeterFlags.TesseractVerbosity] = {
        name: 'Tesseract Verbosity',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.DataGathering.Concurrency] = {
        name: 'Concurrency',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.EstimatedDepletion.Days] = {
        name: 'Estimated Depletion Alert Days to Depletion',
        getReadableValue: valFuncs['']
    };

    // String Array settings
    detailMap[UpdateKeyMap.Entity.Notes] = {
        name: 'Dealer Notes',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.PageCountType] = {
        name: 'Page Count Type',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Text.ServiceProvider] = {
        name: 'Text Alerts Service Provider',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Text.Alert] = {
        name: 'Text Alerts',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.MeterFlags.FlagArray] = {
        name: 'Meter Flags',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.BillingPreferences.Schedule.Expression] = {
        name: 'Billing Schedule Expression',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.BillingPreferences.Schedule.Timezone] = {
        name: 'Billing Schedule Timezone',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.SNMP.Communities] = {
        name: 'Communities',
        getReadableValue: valFuncs['']
    };

    // Email Array settings
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.Emails.Premature] = {
        name: 'Premature Toner Replacement Mailing List',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.Emails.Replacement] = {
        name: 'Toner Replacement Mailing List',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Toner.Emails.Alert] = {
        name: 'General Toner Alert Mailing List',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Emails.ServiceProvider] = {
        name: 'Service Provider Mailing List',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Service.Emails.Alert] = {
        name: 'General Service Alert Mailing List',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Install.New.Emails] = {
        name: 'New Install Alert Emails',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Install.StaleBackOnline.Emails] = {
        name: 'Stale Install Back Online Alert Emails',
        getReadableValue: valFuncs['overwritableArray']
    };
    detailMap[UpdateKeyMap.Settings.Alerts.Install.Stale.Emails] = {
        name: 'Stale Install Alert Emails',
        getReadableValue: valFuncs['overwritableArray']
    };

    // Integer Enum settings

    // String Enums settings
    detailMap[UpdateKeyMap.Settings.Upgrade.Auto.Schedule] = {
        name: 'Auto Upgrade Schedule',
        getReadableValue: valFuncs['cron']
    };
    detailMap[UpdateKeyMap.Settings.UploadTime.DayPart] = {
        name: 'Upload Time (Day Part)',
        getReadableValue: valFuncs['']
    };

    detailMap[UpdateKeyMap.Settings.SNMP.V3Algorithm] = {
        name: 'SNMP V3 Algorithm',
        getReadableValue: valFuncs['']
    };

    // ISODate settings
    detailMap[UpdateKeyMap.Settings.UploadTime.TimeOfDay] = {
        name: 'Upload Time (Time of Day)',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Entity.ExpiresOn] = {
        name: 'Account Expiration Date',
        getReadableValue: valFuncs['']
    };

    // String settings
    detailMap[UpdateKeyMap.Settings.SNMP.Community] = {
        name: 'SNMP Community',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.SNMP.V3Username] = {
        name: 'SNMP V3 Username',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.SNMP.V3AuthPassword] = {
        name: 'SNMP V3 Authentication Password',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.SNMP.V3PrivacyPassword] = {
        name: 'SNMP V3 Privacy Password',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.SNMP.V3PrivacyProtocol] = {
        name: 'SNMP V3 Privacy Protocol',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.SNMP.V3Context] = {
        name: 'SNMP V3 Context',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.DataGathering.Schedules.Meter] = {
        name: 'Tracking Schedule',
        getReadableValue: valFuncs['cron']
    };
    detailMap[UpdateKeyMap.Device.AssetId] = {
        name: 'Asset ID',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Device.CustomLocation] = {
        name: 'Custom Location',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Device.Note] = {
        name: 'Note',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Device.CustomSerialNum] = {
        name: 'Custom Serial Number',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.BillingPreferences.Schedule.Expression] = {
        name: 'Billing Schedule Expression',
        getReadableValue: valFuncs['cron']
    };
    detailMap[UpdateKeyMap.Settings.DataGathering.EmbeddedWebpage.Credentials.Username] = {
        name: 'Device Webpage Username',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.DataGathering.EmbeddedWebpage.Credentials.Password] = {
        name: 'Device Webpage Password',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.GeneralInformation.OnsiteContact.Email] = {
        name: 'On-site Contact Email',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.GeneralInformation.OnsiteContact.Phone] = {
        name: 'On-site Contact Phone Number',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.GeneralInformation.OnsiteContact.Name] = {
        name: 'On-site Contact Name',
        getReadableValue: valFuncs['']
    };

    // ObjectId Settings
    detailMap[UpdateKeyMap.Settings.DataGathering.Dgi.Key] = {
        name: 'DGI Key',
        getReadableValue: valFuncs['']
    };

    // Object settings
    detailMap[UpdateKeyMap.Settings.BillingPreferences.Counters] = {
        name: 'Billing Preferences',
        getReadableValue: valFuncs['object'] // Spencer 9/21/20 TODO - Make a useful readable value for billing meters
    };
    detailMap[UpdateKeyMap.Device.SkipAlerts] = {
        name: 'Skip Alerts',
        getReadableValue: valFuncs['object'] // Spencer 9/30/21 TODO - like above
    };
    detailMap[UpdateKeyMap.Entity.DynamicDeviceGroups] = {
        name: 'Dynamic Device Groups',
        getReadableValue: valFuncs['object']
    };

    // Integration settings
    detailMap[UpdateKeyMap.Settings.Integrations.EAutomate.SalesQuote.Enabled] = {
        name: 'EAutomate Sales Quote Integration Enabled',
        getReadableValue: valFuncs['enabled/disabled']
    };
    detailMap[UpdateKeyMap.Settings.Supplies.Consolidation.WithinDaysRemaining] = {
        name: 'Supplies Consolidation Within Days Remaining',
        getReadableValue: valFuncs['']
    };
    detailMap[UpdateKeyMap.Settings.Supplies.Consolidation.WithinPercentRemaining] = {
        name: 'Supplies Consolidation Within Percent Remaining',
        getReadableValue: valFuncs['']
    };

    // SDS Settings
    detailMap[UpdateKeyMap.Settings.Sds.Enabled] = {
        name: 'HP SDS Integration',
        getReadableValue: valFuncs['enabled/disabled']
    };

    // This is a default SettingDetail object, in case we try to obtain details about
    // a setting that has not been recorded in our detailMap yet.
    const defaultSettingDetails: SettingDetails = {
        name: 'Unmapped Setting',
        getReadableValue: valFuncs['']
    };

    // This is a public-facing function that will return details if possible
    // and fallback to the default otherwise.
    export function getDetails(key: UpdateKeyType): SettingDetails {
        return detailMap[key] || defaultSettingDetails;
    }

    export function createMessage<T>(changePath: UpdateKeyType, newVal: T): string {
        const details = SettingDetails.getDetails(changePath);
        return `${details.name} changed to '${details.getReadableValue(newVal)}'`;
    }

    export function createUnsetMessage(changePath: UpdateKeyType): string {
        return `${SettingDetails.getDetails(changePath).name} was reset`;
    }
}
